"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenCardOptionsMenu = useOpenCardOptionsMenu;
var cardSelection_1 = require("@shared/api/providers/cardSelection");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var render_1 = require("@shared/components/blocks/contexts/render");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var actionSheet_1 = require("@shared/util/actionSheet");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var PrintContext_1 = require("@shared/util/print/PrintContext");
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var useRemoveCardWithConfirmation_1 = require("./useRemoveCardWithConfirmation");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var useBulkEditMenuItem_1 = require("./useBulkEditMenuItem");
var react_native_1 = require("react-native");
function useOpenCardOptionsMenu(card, options) {
    var _a;
    var _b = options.showEdit, showEdit = _b === void 0 ? true : _b, onOpen = options.onOpen, onClose = options.onClose, onRemoved = options.onRemoved, onShare = options.onShare, onDownload = options.onDownload;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var navigate = (0, useNavigator_1.useNavigator)().navigate;
    var duplicateCard = (0, hooks_1.useDuplicateCard)();
    var removeCardWithConfirmation = (0, useRemoveCardWithConfirmation_1.useRemoveCardWithConfirmation)(card, onRemoved);
    var _c = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext), canCreateCard = _c.canCreateCard, canUpdateCard = _c.canUpdateCard, canDeleteCard = _c.canDeleteCard;
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var printContext = (0, react_1.useContext)(PrintContext_1.PrintContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var selectionState = (0, cardSelection_1.useCardSelectionState)();
    var _d = (0, cardSelection_1.useCardSelectionActions)(), setSelectionActive = _d.setActive, addCard = _d.addCard;
    var isCollectionView = renderContext && !['form', 'detail'].includes(renderContext);
    var cardSelectionEnabled = selectionState && isCollectionView && isDesktop;
    var isLowCodeCard = (0, lodash_1.isNil)(card.workflowTemplateId);
    var workflowTemplate = (0, hooks_2.useWorkflowTemplate)(card.workflowTemplateId).workflowTemplate;
    var isAuditTrailEnabled = (workflowTemplate || {}).isAuditTrailEnabled;
    var scheduledBatchUpdateEnabled = useScheduledBatchUpdateEnabled();
    var recurringEntitiesScheduleId = (_a = card.fields) === null || _a === void 0 ? void 0 : _a.entityBatch;
    var showBulkUpdateItem = scheduledBatchUpdateEnabled &&
        !(0, lodash_1.isNil)(recurringEntitiesScheduleId) &&
        !(0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId);
    var cardId = card.id;
    var channelSlug = channel === null || channel === void 0 ? void 0 : channel.slug;
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var navigateToCardUpdate = (0, react_1.useCallback)(function () {
        if ((0, lodash_1.isString)(channelSlug)) {
            navigate('CardUpdate', { cardId: cardId, channelSlug: channelSlug });
        }
    }, [navigate, cardId, channelSlug]);
    var navigateToBulkUpdate = (0, useBulkEditMenuItem_1.useBulkEditMenuItem)({
        cardId: cardId,
        channelSlug: channelSlug,
        workflowTemplateId: workflowTemplateId,
        recurringEntitiesScheduleId: recurringEntitiesScheduleId,
    });
    var navigateToCardHistory = (0, react_1.useCallback)(function () {
        if ((0, lodash_1.isString)(channelSlug)) {
            navigate('CardHistory', { cardId: cardId, channelSlug: channelSlug });
        }
    }, [navigate, cardId, channelSlug]);
    return (0, react_1.useCallback)(function (anchor) {
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
        return actionSheet_1.MBActionSheet.show({
            parent: anchor,
            webVerticalAlignment: 'below',
            webHorizontalAlignment: 'right',
            onClose: onClose,
            options: [
                {
                    title: (0, i18n_1.i18n)('common.edit'),
                    hidden: !showEdit || !canUpdateCard.enabled,
                    onPress: navigateToCardUpdate,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.duplicate'),
                    hidden: !canCreateCard.enabled,
                    onPress: function () { return duplicateCard(card.id); },
                },
                {
                    title: (0, i18n_1.i18n)('common.share'),
                    onPress: onShare,
                },
                {
                    title: (0, i18n_1.i18n)('common.download'),
                    hidden: (0, lodash_1.isNil)(onDownload) || isLowCodeCard,
                    onPress: onDownload,
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.cardHistory'),
                    hidden: !isAuditTrailEnabled,
                    onPress: navigateToCardHistory,
                },
                {
                    title: (0, i18n_1.i18n)('card.bulkUpdate.menu.menuItem'),
                    hidden: !showBulkUpdateItem,
                    onPress: function () { return navigateToBulkUpdate(anchor); },
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.print'),
                    hidden: !printContext,
                    onPress: function () {
                        analytics_1.MBAnalytics.track('PrintCard', { cardId: card.id, channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
                        printContext.onPrint();
                    },
                },
                {
                    title: (0, i18n_1.i18n)('card.menu.actions.selectMultiple'),
                    hidden: !cardSelectionEnabled,
                    onPress: function () {
                        addCard(card);
                        setSelectionActive(true);
                    },
                },
                {
                    title: (0, i18n_1.i18n)('common.delete'),
                    destructive: true,
                    hidden: !canDeleteCard.enabled,
                    onPress: function () { return removeCardWithConfirmation(anchor); },
                },
            ],
        });
    }, [
        onOpen,
        onClose,
        showEdit,
        canUpdateCard.enabled,
        navigateToCardUpdate,
        navigateToBulkUpdate,
        canCreateCard.enabled,
        onShare,
        onDownload,
        isLowCodeCard,
        isAuditTrailEnabled,
        navigateToCardHistory,
        printContext,
        cardSelectionEnabled,
        canDeleteCard.enabled,
        duplicateCard,
        card,
        channel === null || channel === void 0 ? void 0 : channel.slug,
        addCard,
        setSelectionActive,
        removeCardWithConfirmation,
        showBulkUpdateItem,
    ]);
}
function useScheduledBatchUpdateEnabled() {
    var scheduledBatchUpdateWebEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.SCHEDULED_BATCH_UPDATE);
    var scheduledBatchUpdateNativeEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.SCHEDULED_BATCH_UPDATE_NATIVE);
    return react_native_1.Platform.OS === 'web' ? scheduledBatchUpdateWebEnabled : scheduledBatchUpdateNativeEnabled;
}
