"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpcomingEntityFieldOverrides = useUpcomingEntityFieldOverrides;
var lodash_1 = require("lodash");
var react_1 = require("react");
function useUpcomingEntityFieldOverrides(components) {
    return (0, react_1.useMemo)(function () {
        var componentsWithOverrides = components === null || components === void 0 ? void 0 : components.filter(isComponentWithUpcomingFieldOverrides).filter(function (component) { var _a; return !(0, lodash_1.isNil)((_a = component.upcomingRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value); });
        if ((0, lodash_1.isNil)(componentsWithOverrides) || (0, lodash_1.isEmpty)(componentsWithOverrides)) {
            return undefined;
        }
        var overrides = {};
        componentsWithOverrides.forEach(function (component) {
            var _a;
            overrides[component.id] = (_a = component.upcomingRecurringEntityDefault) === null || _a === void 0 ? void 0 : _a.value;
        });
        return {
            fields: overrides,
        };
    }, [components]);
}
function isComponentWithUpcomingFieldOverrides(component) {
    return component.type === 'TAG' || component.type === 'AUDIT_TAG';
}
