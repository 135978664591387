"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelOptions = MBChannelOptions;
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var reportLink_1 = require("@shared/scenes/channel/options/components/reportLink");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var alert_1 = require("@shared/util/alert");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/permissions/hooks");
var workflow_1 = require("@shared/util/workflow");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var externalFormRow_1 = require("../containers/externalFormRow");
var header_1 = require("./header");
var pinChatRow_1 = require("./pinChatRow");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var config_1 = require("@shared/config");
function MBChannelOptions(_a) {
    var channel = _a.channel, reports = _a.reports, isDesktop = _a.isDesktop, externalFormTemplate = _a.externalFormTemplate, workflowTemplate = _a.workflowTemplate, ability = _a.ability, onStartAddingUsers = _a.onStartAddingUsers, onOpenUserList = _a.onOpenUserList, onUpdatePinned = _a.onUpdatePinned, onNotificationSettings = _a.onNotificationSettings, onOpenReport = _a.onOpenReport, onLeaveChannel = _a.onLeaveChannel, onFragmentTemplates = _a.onFragmentTemplates, onWorkflowEdit = _a.onWorkflowEdit, onSubforms = _a.onSubforms, onViewTemplates = _a.onViewTemplates, onAdvancedChannelOptions = _a.onAdvancedChannelOptions;
    var componentContext = useComponentContext({
        onLeaveChannel: onLeaveChannel,
        channel: channel,
        workflowTemplateId: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id,
        ability: ability,
        isDesktop: isDesktop,
    });
    var isBuilderEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.BUILDER);
    var onClickAutomations = function () {
        var channelUrl = window.location.href.replace(/\/cards\/infos/, '');
        window.location.href = "".concat(config_1.Config.BUILDER_APP_URL, "/").concat(workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, "/automation?returnTo=").concat(channelUrl);
    };
    return (react_1.default.createElement(react_native_1.ScrollView, { keyboardShouldPersistTaps: "always", contentInsetAdjustmentBehavior: "automatic", style: { backgroundColor: componentContext.colors.backgroundInactive } },
        react_1.default.createElement(react_native_1.SafeAreaView, null,
            react_1.default.createElement(header_1.MBChannelOptionsHeader, { channel: channel }),
            componentContext.canCreateReportUrls && (reports === null || reports === void 0 ? void 0 : reports.length) > 1 && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(typography_1.MBTextOverlineBold, { style: { paddingHorizontal: (0, components_1.grid)(2) }, color: componentContext.colors.foregroundInactive }, (0, i18n_1.i18n)('channel.options.reports.header')),
                react_1.default.createElement(Section, { isDesktop: isDesktop }, reports.map(function (link) { return (react_1.default.createElement(reportLink_1.MBReportLink, { key: link.id, reportLink: link, onPress: onOpenReport })); })))),
            react_1.default.createElement(Section, { isDesktop: isDesktop },
                componentContext.canCreateReportUrls && (reports === null || reports === void 0 ? void 0 : reports.length) === 1 && (react_1.default.createElement(reportLink_1.MBReportLink, { reportLink: reports[0], onPress: onOpenReport, single: true })),
                !(0, lodash_1.isNil)(externalFormTemplate) && (react_1.default.createElement(externalFormRow_1.MBExternalFormRow, { channel: channel, viewTemplateId: (0, workflow_1.getViewTemplateId)(externalFormTemplate) })),
                react_1.default.createElement(components_1.MBSnoozeRow, { title: (0, i18n_1.i18n)('channel.options.notifications.title'), titleWhenSnoozed: (0, i18n_1.i18n)('channel.options.notifications.titleSnoozed'), colorWhenSnoozed: componentContext.colors.foregroundActive, imageWhenSnoozed: require('@shared/assets/icons/v2/bell-muted.png'), snoozeUntil: !(0, lodash_1.isNil)(channel.snoozeUntil) && new Date(channel.snoozeUntil), onPress: onNotificationSettings }),
                react_1.default.createElement(pinChatRow_1.MBChannelOptionsPinChatRow, { channel: channel, onUpdatePinned: onUpdatePinned }),
                componentContext.showFragmentTemplates && (react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.actionLink'), icon: require('@shared/assets/icons/v2/pencil-paper.png'), onPress: onFragmentTemplates })),
                componentContext.showNoCodeFeatures && (react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('workflowTemplate.workflowEditTitle'), subtitle: (0, i18n_1.i18n)('workflowTemplate.workflowEditDescription'), icon: require('@shared/assets/icons/v2/list.png'), onPress: onWorkflowEdit })),
                componentContext.showNoCodeFeatures && hasSubformComponent(workflowTemplate) && (react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('workflowTemplate.subformsLinkTitle'), subtitle: (0, i18n_1.i18n)('workflowTemplate.subformsLinkDescription'), icon: require('@shared/assets/icons/v2/check-todo.png'), onPress: onSubforms })),
                componentContext.showNoCodeFeatures && componentContext.canUpdateWorkflowTemplate && (react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('workflowTemplate.viewTemplate.list.linkTitle'), subtitle: (0, i18n_1.i18n)('workflowTemplate.viewTemplate.list.linkDescription'), icon: require('@shared/assets/icons/v2/pencil-paper.png'), onPress: onViewTemplates })),
                isBuilderEnabled &&
                    componentContext.showNoCodeFeatures &&
                    componentContext.canUpdateWorkflowTemplate && (react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('workflowTemplate.builder.automations.title'), subtitle: (0, i18n_1.i18n)('workflowTemplate.builder.automations.description'), icon: require('@shared/assets/icons/v2/lightning-bolt.png'), onPress: onClickAutomations }))),
            react_1.default.createElement(Section, { isDesktop: isDesktop },
                react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('channel.options.members', { count: channel.users.length }), icon: require('@shared/assets/icons/v2/address-book.png'), onPress: onOpenUserList }),
                !['DM', 'SUPPORT'].includes(channel.type) &&
                    componentContext.createPermission.enabled && (react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('channel.options.addPeople'), icon: require('@shared/assets/icons/v2/avatar-invite.png'), onPress: onStartAddingUsers }))),
            react_1.default.createElement(Section, { isDesktop: isDesktop },
                react_1.default.createElement(components_1.MBIconRow, { title: react_1.default.createElement(typography_1.MBTextBody1, null, (0, i18n_1.i18n)('channel.options.advanced.title')), icon: require('@shared/assets/icons/v2/gear.png'), onPress: onAdvancedChannelOptions })),
            react_1.default.createElement(Section, { isDesktop: isDesktop },
                react_1.default.createElement(components_1.MBIconRow, { title: (0, i18n_1.i18n)('channel.options.leave.title', { channelName: channel.name }), icon: require('@shared/assets/icons/v2/arrow-exit.png'), isHidingPressableIndicator: true, onPress: componentContext.confirmLeave })))));
}
function Section(_a) {
    var children = _a.children, isDesktop = _a.isDesktop;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { style: [
            { backgroundColor: colors.background, marginVertical: (0, components_1.grid)(1) },
            isDesktop && styles_1.SharedStyles.borderRadius,
        ] }, children));
}
function hasSubformComponent(workflowTemplate) {
    return !(0, lodash_1.isNil)(workflowTemplate) && workflowTemplate.components.some(hooks_1.isSubformComponent);
}
function useComponentContext(options) {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var confirmLeave = (0, react_1.useCallback)(function () {
        alert_1.MBAlert.confirm({
            title: (0, i18n_1.i18n)('channel.options.leave.confirmation.title'),
            message: (0, i18n_1.i18n)('channel.options.leave.confirmation.message'),
            confirmStyle: 'destructive',
            confirmText: (0, i18n_1.i18n)('channel.options.leave.confirmation.confirm'),
            onConfirm: options.onLeaveChannel,
        });
    }, [options.onLeaveChannel]);
    var createPermission = (0, hooks_2.usePermission)({
        action: 'create',
        subject: 'ChannelMembership',
        channelId: options.channel.id,
    });
    var canCreateFragmentTemplates = (0, hooks_2.usePermission)({
        action: 'create',
        subject: 'CardFragmentTemplate',
        channelId: options.channel.id,
    }).enabled;
    var showFragmentTemplates = canCreateFragmentTemplates && !(0, workflow_1.hasVirtualCardDefinition)(options.channel);
    var canReadWorkflowTemplate = (0, hooks_2.usePermission)({
        action: 'read',
        subject: 'WorkflowTemplate',
    }).enabled;
    var showNoCodeFeatures = canReadWorkflowTemplate && (0, workflow_1.hasVirtualCardDefinition)(options.channel) && options.isDesktop;
    var canUpdateWorkflowTemplate = options.ability.can('update', 'WorkflowTemplate', "id: { $eq: ".concat(options.workflowTemplateId, " }"));
    var canCreateReportUrls = (0, hooks_2.usePermission)({
        action: 'create',
        subject: 'CreateReportUrl',
        channelId: options.channel.id,
    }).enabled;
    return {
        colors: colors,
        canCreateReportUrls: canCreateReportUrls,
        canUpdateWorkflowTemplate: canUpdateWorkflowTemplate,
        confirmLeave: confirmLeave,
        createPermission: createPermission,
        showFragmentTemplates: showFragmentTemplates,
        showNoCodeFeatures: showNoCodeFeatures,
    };
}
